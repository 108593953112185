import queryString from 'query-string';


const queryStringGenerator = (params) => {
  return (
    '?' +
    queryString.stringify(params, { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true })
  );
};

const camelizedString = (str) => {
  return str.replace(/(_\w)/g, (m) => m[1].toUpperCase());
};

export class QueryMixin {
  constructor(props) {
    this.props = props
  }

  getParam(name) {
    const { state, search } = this.props.location
    const queryParams = queryString.parse(search);

    if (state && state[name]) {
      return state[name];
    }

    if (queryParams[name]) {
      return queryParams[name];
    }

    if ((this.defaultState || {})[camelizedString(name)]) {
      return this.defaultState[camelizedString(name)];
    }

    return '';
  }
  setParams(state) {
    const new_query_str = queryStringGenerator(state)
    this.props.history.replace({
      pathname: this.props.location.pathname,
      state: state,
      search: queryStringGenerator(state),
    });
  }
}
