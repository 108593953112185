// // import { combineReducers } from 'redux';
import user from './user';
import widget from './widget';
// // import badge from './badge';
// // import order from './order';
// // import performer_orders from './performer-orders';

// export default combineReducers({
//   // user,
//   // cable,
//   // badge,
//   // order,
//   // performer_orders,
// });


export default {
  user,
  widget,
};
