const styles = {
	root: {
		flexGrow: 1,
	},
	buttonContainer: {
		height: '50vh',
	},
};

export default styles;
