import React, { Component } from 'react';
import Rest from 'tools/rest';
import { debounce, isEqual as _isEqual, replace as _replace } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Layout,
  Table,
  FloatButton,
  Select,
  Input,
  Row,
  Col,
  Form,
  Tag,
  Modal,
  InputNumber,
  Typography,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { InfoCircleOutlined } from '@ant-design/icons';
import { parseISO as _parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';

import QueryMixin from 'components/query_mixin';
import Preloader from 'components/preloader';
import AgreementCard from 'components/agreement_card';
import GotoAccountButton from 'components/widget/goto_account_button';

const { Text } = Typography;

class Agreements extends QueryMixin {
  state = {
    lb_agreements: [],
    meta: {
      page: this.getQuery('page'),
      per: this.getQuery('per'),
      total: 0,
    },
    search: {
      street: this.getQuery('street'),
      building: this.getQuery('building'),
      flat: this.getQuery('flat'),
      name: this.getQuery('name'),
      number: this.getQuery('number'),
      phone: this.getQuery('phone'),
    },
    streetOptions: [],
    buildingOptions: [],
    flatOptions: [],
    loading: false,
    useDebounce: false,
    visibleCard: false,
    agreement: {},
  };

  loadAgreements = () => {
    const params = {
      page: this.state.meta.page,
      per: this.state.meta.per,
      filter: this.state.search,
    };
    if (this.loadRequest) this.loadRequest.cancel();

    this.setState({ loading: true, useDebounce: false });

    this.loadRequest = Rest.get('/api/v1/lb_agreements.json', { params: params }).then(
      (response) => {
        const { lb_agreements, meta } = response.data;
        this.setState({
          lb_agreements,
          meta,
          loading: false,
          agreement: lb_agreements.length > 0 ? lb_agreements[0] : {},
          visibleCard: this.getQuery('visibleCard') ? true : this.state.visibleCard
        });
        this.setQuery({
          ...this.state.search,
          page: meta.page,
          per: meta.per,
        });
      },
    );
  };

  componentWillUnmount() {
    if (this.loadRequest) this.loadRequest.cancel();
    document.title = _replace(document.title, ' | Договоры', '')
  }

  componentDidMount() {
    document.title += ' | Договоры'
    this.loadAgreements();
    this.loadStreetOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { street, building } = this.state.search;
    if (
      !_isEqual(prevState.search, this.state.search) ||
      !_isEqual(prevState.meta.page, this.state.meta.page) ||
      !_isEqual(prevState.meta.per, this.state.meta.per)
    ) {
      if (this.debounceLoad) {
        this.debounceLoad.cancel();
      }
      this.debounceLoad = debounce(() => {
        this.loadAgreements();
      }, 500);

      this.debounceLoad();

      if (!this.state.useDebounce) {
        this.debounceLoad.flush();
      }
    }

    if (street && street !== prevState.search.street) {
      this.setState({
        search: {
          ...this.state.search,
          building: '',
        },
      });
      this.loadBuildingOptions();
    }

    if (building && building !== prevState.search.building) {
      this.setState({
        search: {
          ...this.state.search,
          flat: '',
        },
      });
      this.loadFlatOptions();
    }

    if (!street && street !== prevState.search.street) {
      this.setState({
        search: {
          ...this.state.search,
          building: '',
        },
      });
    }

    if (!building && building !== prevState.search.building) {
      this.setState({
        search: {
          ...this.state.search,
          flat: '',
        },
      });
    }
  }

  loadStreetOptions = () => {
    const { street } = this.state.search;

    Rest.get(`/api/v1/addresses.json?query=${street}`).then((res) => {
      const { suggestions } = res.data;

      this.setState({
        streetOptions: suggestions,
      });
    });
  };

  loadBuildingOptions = () => {
    const { street } = this.state.search;

    Rest.get(`/api/v1/addresses/houses.json?street=${street}`).then((res) => {
      const { suggestions } = res.data;
      const options = suggestions.map((s) => {
        return { id: s.id, value: s.value, label: s.value };
      });
      this.setState({
        buildingOptions: options,
      });
    });
  };

  loadFlatOptions = () => {
    const { building } = this.state.search;

    Rest.get(`/api/v1/addresses/flats.json?building_id=${building}`).then((res) => {
      const options = res.data.map((f) => {
        return { id: f.flat_id, value: f.name, label: f.name };
      });
      this.setState({
        flatOptions: options,
      });
    });
  };

  handleChangeText = (e) => {
    this.setState({
      useDebounce: true,
      meta: { ...this.state.meta, page: 1 },
      search: { ...this.state.search, [e.target.name]: e.target.value },
    });
  };

  handleChooseSearchStreet = (v) => {
    this.setState({
      search: {
        ...this.state.search,
        street: v,
      },
      meta: { ...this.state.meta, page: 1 },
    });
  };

  handleChooseSearchBuilding = (v) => {
    this.setState({
      search: {
        ...this.state.search,
        building: v,
      },
      meta: { ...this.state.meta, page: 1 },
    });
  };

  handleChooseSearchFlat = (v) => {
    this.setState({
      search: {
        ...this.state.search,
        flat: v,
      },
      meta: { ...this.state.meta, page: 1 },
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    // const newsorter = {
    //   order: sorter.order == undefined ? 'desc' : sorter.order.replace('end', ''),
    //   order_by: sorter.column == undefined ? 'created_at' : sorter.field,
    // };
    this.setState({
      meta: {
        page: pagination.current,
        per: pagination.pageSize,
      }
    });
  };

  render() {
    const {
      loading,
      search,
      streetOptions,
      buildingOptions,
      flatOptions,
      visibleCard,
      agreement,
      meta,
    } = this.state;
    const pagination = {
      current: meta.page,
      pageSize: meta.per,
      total: meta.total,
      position: ['bottomCenter'],
      defaultCurrent: '1',
      showSizeChanger: true,
    };

    const columns = [
      { title: '№ договора', dataIndex: 'number', key: 'number' },
      { title: 'ФИО', dataIndex: 'name', key: 'name' },
      { title: 'Адрес', dataIndex: 'address', key: 'address' },
      { title: 'Баланс', dataIndex: 'balance', key: 'balance' },
      {
        title: 'Способ счета',
        dataIndex: 'bill_delivery',
        key: 'bill_delivery',
        render: (val) => {
          switch (val) {
            case 'all':
              return 'Электронный+Бумажный';
            case 'email':
              return 'Электронный';
            case 'receipt':
              return 'Бумажный';
          }
        },
      },
      {
        title: 'Статус ЛК',
        dataIndex: 'lk_status',
        key: 'lk_status',
        render: (val) => {
          switch (val) {
            case 'confirmed_lk':
              return 'ПЛК';
            case 'unconfirmed_lk':
              return 'ЛК';
            case 'no_lk':
              return 'БЕЗ ЛК';
          }
        },
      },
    ];

    return (
      <React.Fragment>
        <FloatButton.BackTop />
        <PageHeader title="Договоры"></PageHeader>
        <Row justify="space-between">
          <Col>
            <Form layout="inline" style={{ marginBottom: 16 }}>
              <Form.Item>
                <Input
                  name="number"
                  value={search.number}
                  placeholder="Номер договора"
                  onChange={this.handleChangeText}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  name="name"
                  value={search.name}
                  placeholder="ФИО"
                  onChange={this.handleChangeText}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  controls={false}
                  name="phone"
                  value={search.phone}
                  placeholder="Телефон"
                  onChange={this.handleChangeText}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  value={search.street == '' ? undefined : search.street}
                  allowClear
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Улица"
                  optionFilterProp="children"
                  onChange={this.handleChooseSearchStreet}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {streetOptions.map((street) => {
                    return (
                      <Select.Option key={street.id} value={street.value}>
                        {street.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  style={{ width: 100 }}
                  disabled={!search.street}
                  value={search.building == '' ? undefined : search.building}
                  allowClear
                  showSearch
                  placeholder="Дом"
                  optionFilterProp="children"
                  onChange={this.handleChooseSearchBuilding}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {buildingOptions.map((building) => {
                    return (
                      <Select.Option key={building.id} value={building.id}>
                        {building.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  value={search.flat == '' ? undefined : search.flat}
                  disabled={!search.building}
                  allowClear
                  showSearch
                  placeholder="Квартира"
                  optionFilterProp="children"
                  onChange={this.handleChooseSearchFlat}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {flatOptions.map((flat) => {
                    return (
                      <Select.Option key={flat.id} value={flat.value}>
                        {flat.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Preloader loading={this.state.loading}>
          {visibleCard &&
            <Modal
              // title={`Карточка договора № ${agreement.number}`}
              title={
                <React.Fragment>
                  <Text>Карточка договора № {agreement.number}</Text>
                  < InfoCircleOutlined
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      GotoAccountButton.gotoAccount(agreement.uid, '_blank');
                    }}
                  />
                </React.Fragment>
              }
              visible={visibleCard}
              onCancel={() => { this.setState({ visibleCard: false }) }}
              onOk={() => { this.setState({ visibleCard: false }) }}
              footer={false}
              width={'95%'}
            >
              <AgreementCard agrm_id={agreement.id} />
            </Modal>}
          <div style={{ height: '32px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
            <Text>
              Общее кол-во: {meta.total} шт.
            </Text>
          </div>
          <Table
            rowKey={(record) => record.id}
            loading={loading}
            columns={columns}
            dataSource={this.state.lb_agreements}
            hideOnSinglePage={true}
            onChange={this.handleTableChange}
            pagination={pagination}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => { this.setState({ visibleCard: true, agreement: record }) }, // click row
              };
            }}
          />
        </Preloader>
      </React.Fragment >
    );
  }
}

const styles = (theme) => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
  filterSourceType: {
    width: '150px',
  },
  datePicker: {
    width: '150px',
  },
});

export default withStyles(styles)(Agreements);
