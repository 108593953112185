import React, { useState, useEffect } from 'react';
import Rest from 'tools/rest';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { Form, Button, Table, Typography, Card, Modal, Collapse, Col, List, Row, Checkbox } from 'antd';
import { find as _find, forEach as _forEach, map as _map, reject as _reject, values } from 'lodash';
import { toast } from 'react-toastify';

const { Text, Title } = Typography
const { Meta } = Card;

const NotificationRules = (props) => {
  const [notification_rules, setNotificationRules] = useState([]);
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const { user_id } = props;

  const getNotificationRules = () => {
    const filter = {
      user_id: user_id
    };
    setLoading(true)
    Rest.get(`/api/v1/notification_rules.json`, { params: { filter: filter } }).then((response) => {
      const { notification_rules, options } = response.data;
      setNotificationRules(notification_rules);
      setOptions(options);
      setLoading(false)
    });
  };

  const updateNotificationRules = (target_type) => {
    let notification_rule = {
      user_id: user_id,
      target_type: target_type,
    }

    if (notification_rules[target_type]) {
      notification_rule = notification_rules[target_type]
    }

    const params = {
      notification_rule: notification_rule
    }
    setLoading(true)
    Rest.post(`/api/v1/notification_rules.json`, params).then((response) => {
      const { notification_rule } = response.data;
      toast.success('Правило успешно сохранено');
    }).catch((e) => {
      console.error('error', e);
      toast.error('Ошибка сохранения');
    }).finally(() => {
      setLoading(false)
    });
  };

  useEffect(() => {
    if (user_id) {
      getNotificationRules();
    }
  }, []);

  return (
    <div>
      {_map(options, (option, key)=>(
        <div style={{marginBottom: '40px'}}>
          <Card
            style={{ width: '100%' }}
            key={key}
            title={option.name}
            actions={[
              <Button
                onClick={(e) => { updateNotificationRules(key) }}
              >
                Сохранить
              </Button>,
            ]}
          >
            <Row gutter={[16]}>
              <Col span={8}>
                <Title level={5}>Отслеживаемые типы</Title>
                <Checkbox.Group
                  options={option.types}
                  defaultValue={notification_rules[key]?.searcheble_types}
                  onChange={(values) => {
                    let newData = notification_rules
                    if (!newData[key]) {
                      newData[key] = {
                        user_id: user_id,
                        target_type: key,
                      }
                    }
                    newData[key]["searcheble_types"] = values
                    setNotificationRules(newData)
                  }}
                />
              </Col>
              <Col span={8}>
                <Title level={5}>Отслеживаемые поля</Title>
                <Checkbox.Group
                  options={option.fields}
                  defaultValue={notification_rules[key]?.searcheble_fields}
                  onChange={(values) => {
                    let newData = notification_rules
                    if (!newData[key]) {
                      newData[key] = {
                        user_id: user_id,
                        target_type: key,
                      }
                    }
                    newData[key]["searcheble_fields"] = values
                    setNotificationRules(newData)
                  }}
                />
              </Col>
              <Col span={8}>
                <Title level={5}>Дополнительные поля в уведомлении</Title>
                <Checkbox.Group
                  options={option.fields}
                  defaultValue={notification_rules[key]?.dislay_fields}
                  onChange={(values) => {
                    let newData = notification_rules
                    if (!newData[key]) {
                      newData[key] = {
                        user_id: user_id,
                        target_type: key,
                      }
                    }
                    newData[key]["dislay_fields"] = values
                    setNotificationRules(newData)
                  }}
                />
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default NotificationRules;
