import React from 'react';
import { Tabs, Empty } from 'antd';
import NotificationRules from 'components/notification_rules';
import ProfileForm from './components/profile_form';

const UserCard = (props) => {
  const { user_id } = props

  const tabs = [
    {
      key: 'profile',
      label: 'Профиль',
      children: <ProfileForm user_id={user_id} />
    },
    {
      key: 'notification',
      label: 'Уведомления',
      children: <>
        {user_id ?
          <NotificationRules user_id={user_id} />
          :
          <Empty style={{ marginBottom: '30px' }} />
        }
      </>,
    },
  ];

  return (
    <Tabs defaultActiveKey='profile' items={tabs}/>
  );
};

export default UserCard;
