import React, { useState, useEffect, useContext } from 'react';
import Rest from 'tools/rest';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Select,
  Checkbox,
  Switch,
} from 'antd';
import {
  find as _find,
  forEach as _forEach,
  map as _map,
  reject as _reject,
  isEqual as _isEqual,
} from 'lodash';
import { toast } from 'react-toastify';
import { AbilityContext } from 'tools/ability';

const { Text } = Typography;
let roles = [];
let departments = [];

const ProfileForm = (props) => {
  const [user, setUser] = useState({
    id: null,
    name: null,
    email: null,
    role: null,
    department_id: null,
    active: true,
    chat_id: null,
    lb_manager_id: null,
    password: null,
    check_password: null,
  })
  const [currentUser, setCurrentUser] = useState({})
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({})
  const context = useContext(AbilityContext)
  const can_manage = context.can('manage', 'User')

  const { user_id } = props

  useEffect(() => {
    loadRoles();
    loadDepartments();
    loadUser();
  }, []);

  const loadUser = () => {
    if (!user_id) {return}
    Rest.get(`/api/v1/users/${user_id}`).then((response) => {
      const current_user = {...user, ...response.data.user}
      setUser(current_user);
      setCurrentUser(current_user);
    });
  };

  const updateUser = () => {
    if (!checkPassword()) { return }
    setErrors({})
    const params = {
      user: user,
    }
    Rest.put(`/api/v1/users/${user_id}`, params).then(
      (response) => {
        const { user } = response.data;
        setUser(user);
        setCurrentUser(user);
        toast.success('Изменения сохранены');
      }).catch((e) => {
        setErrors(e.response.data.user.errors)
        toast.error('Ошибка сохранения');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createUser = () => {
    if (!checkPassword()) { return }
    setErrors({})
    const params = {
      user: user,
    }
    Rest.post(`/api/v1/users.json`, params).then(
      (response) => {
        const { user } = response.data;
        setUser(user);
        setCurrentUser(user);
        toast.success('Пользователь создан');
      }).catch((e) => {
        setErrors(e.response.data.user.errors)
        toast.error('Ошибка создания пользователя');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkPassword = () => {
    if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/.test(user?.password))) {
      setErrors({
        ...errors,
        password: ['Пароль должен содержать хотя бы одну цифру,строчную и заглавную буквы, спецсимвол и быть длиной не менее 8 символов']
      })
      return false
    } else {
      setErrors({
        ...errors,
        password: null,
      })
    }
    if (user?.check_password !== user?.password) {
      setErrors({
        ...errors,
        check_password: ['Пароль не совпадает']
      })
      return false
    } else {
      setUser({
        ...user,
        check_password: null,
      })
      setErrors({
        ...errors,
        check_password: null,
      })
      return true
    }
  };

  const loadRoles = () => {
    Rest.get(`/api/v1/directory/user_roles`).then((response) => {
      const { user_roles } = response.data;
      roles = _map(user_roles, (_, role) => {
        return { label: role, value: role }
      })
    });
  };

  const loadDepartments = () => {
    Rest.get(`/api/v1/departments.json`).then((response) => {
      departments = _map(response.data.departments, (department) => {
        return { label: department.name, value: department.id }
      })
    });
  };

  const handleChangeText = (e) => {
    const text = e.target.value?.trim() ? e.target.value : null
    setUser({ ...user, [e.target.name]: text })
    setErrors({ ...errors, [e.target.name]: null })
  };

  const handleChangeSelector = (value, option_name) => {
    setUser({ ...user, [option_name]: value });
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Row>
        {user.pass_is_old &&
          <div style={{ textAlign: 'center', margin: '0px 15%' }}>
            <Text style={{ fontSize: '22px' }} type='danger'>
              <b>Внимание! До 15 сентября </b> необходимо сменить пароль, иначе доступ к учетной записи будет заблокирован!
              <br />
              Новый пароль должен отвечать требованиям безопасности: сожержать хотя бы одну цифру, строчную и заглавную буквы, спецсимвол и быть длиной не менее 8 символов.
            </Text>
          </div>
        }
      </Row>
      <Row gutter={10}>
        <Col span={5}>
          <Form.Item
            label="Имя"
            help={errors?.name && errors?.status.join(", ")}
            validateStatus={errors?.name && "error"}
            labelCol={{span: 5}}
            wrapperCol={{ span: 19 }}
          >
            <Input
              controls={false}
              name="name"
              value={user?.name}
              onChange={handleChangeText}
            />
          </Form.Item>
          <Form.Item
            label="Логин"
            help={errors?.email && errors?.email.join(", ")}
            validateStatus={errors?.email && "error"}
            labelCol={{span: 5}}
            wrapperCol={{ span: 19 }}
          >
            <Input
              controls={false}
              name="email"
              value={user?.email}
              onChange={handleChangeText}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Роль"
            help={errors?.role && errors?.role.join(", ")}
            validateStatus={errors?.role && "error"}
          >
            {can_manage ?
              <Select
                allowClear
                showSearch
                value={user.role == '' ? undefined : user.role}
                placeholder='роль'
                optionFilterProp="children"
                filterOption={(input, option) => _includes(option.label.toLowerCase(), input.toLowerCase())}
                options={roles}
                onChange={(value)=> {handleChangeSelector(value, 'role')}}
              />
              :
              <Text>{user.role}</Text>
            }
          </Form.Item>
          <Form.Item
            label="Отдел"
            help={errors?.department_id && errors?.department_id.join(", ")}
            validateStatus={errors?.department_id && "error"}
          >
            {can_manage ?
              <Select
                allowClear
                showSearch
                value={user.department_id == '' ? undefined : user.department_id}
                placeholder='отдел'
                optionFilterProp="children"
                filterOption={(input, option) => _includes(option.label.toLowerCase(), input.toLowerCase())}
                options={departments}
                onChange={(value)=> {handleChangeSelector(value, 'department_id')}}
              />
              :
              <Text>{_find(departments, { value: user.department_id })?.label}</Text>
            }
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="ID ТГ"
            help={errors?.chat_id && errors?.chat_id.join(", ")}
            validateStatus={errors?.chat_id && "error"}
          >
            <Input
              controls={false}
              name="chat_id"
              value={user?.chat_id}
              onChange={handleChangeText}
            />
          </Form.Item>
          <Form.Item
            label="ID LB"
            help={errors?.lb_manager_id && errors?.lb_manager_id.join(", ")}
            validateStatus={errors?.lb_manager_id && "error"}
          >
            <Input
              controls={false}
              name="lb_manager_id"
              value={user?.lb_manager_id}
              onChange={handleChangeText}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            label="Новый пароль"
            help={errors?.password && errors?.password.join(", ")}
            validateStatus={errors?.password && "error"}
          >
            <Input.Password
              // defaultValue={null}
              controls={false}
              name="password"
              value={user?.password}
              onChange={handleChangeText}
            />
          </Form.Item>
          <Form.Item
            label="Повторите пароль"
            help={errors?.check_password && errors?.check_password.join(", ")}
            validateStatus={errors?.check_password && "error"}
          >
            <Input.Password
              // defaultValue={null}
              controls={false}
              name="check_password"
              value={user?.check_password}
              onChange={handleChangeText}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Row justify={'center'}>
            <Form.Item
              help={errors?.active && errors?.active.join(", ")}
              validateStatus={errors?.active && "error"}
              labelCol={{span: 0}}
            >
              <Switch
                style={{width: '100px'}}
                checked={user.active}
                checkedChildren={'Аткивен'}
                unCheckedChildren={'Не активен'}
                onChange={(checked) => { setUser({...user, active: checked}) }}
                disabled={!can_manage}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
      <Row justify={'center'}>
        <Button
          style={{ width: '350px' }}
          type='primary'
          htmlType="submit"
          onClick={user?.id ? updateUser : createUser}
          disabled={_isEqual(user, currentUser)}
        >
          {user?.id ? 'Сохранить' : 'Создать'}
        </Button>
      </Row>
    </Form>
  );
};

export default ProfileForm;
